export enum ClinicPath {
  FRONT = '/clinic',
  GENERAL = '/clinic/general',
  LOCATION = '/clinic/location',
  ROOM = '/clinic/room',
  SPECIALISTS = '/clinic/specialists',
  TREATMENTS = '/clinic/treatments',
  TECHNOLOGIES = '/clinic/technologies',
  INSUARANCE = '/clinic/insurance',
  POLICY = '/clinic/policy',
}
