import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { Path } from '../../static/enums/Path'
import styles from './FourOhFour.module.scss'
import { Button, ButtonsColors, ButtonsSizes } from '../../core/components/Button'
import FourOhFourImageDescription from '../../assets/components-icons/404/eva.svg'
import Image from '../Image'
interface Props {
  localization: FourOhFourLocalization
}

export const FourOhFour: FC<Props> = props => {
  const { push } = useRouter()

  const { localization } = props

  return (
    <div className={styles.background}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.image}>
            <Image src={'/img/404.png'} width={720} height={299} alt="Page not found" />
          </div>

          <div className={styles.info}>
            <div className={styles.infoBody}>
              <div className={styles.infoImage}>
                <FourOhFourImageDescription viewBox="0 0 40 44" />
              </div>
              <div>
                <div className={styles.title}>{localization.title}</div>
                <div className={styles.description}>{localization.description}</div>
              </div>
            </div>

            <div className={styles.buttonWrapper}>
              <Button
                onClick={() => push(Path.SEARCH)}
                color={ButtonsColors.BLUE}
                size={ButtonsSizes.LARGE}
                text={localization.button}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface FourOhFourLocalization {
  title: string
  description: string
  button: string
}
