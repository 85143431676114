import React, { FC } from 'react'
import { Path } from '../../static/enums/Path'
import { useRouter } from 'next/router'
import { Layout } from '../../components/Layout'
import { FourOhFour, FourOhFourLocalization } from '../../components/404/FourOhFour'
import { ClinicPath } from '../../static/enums/ClinicPath'
import { PersonalPath } from '../../static/enums/PersonalPath'
import { SpecialistPath } from '../../static/enums/SpecialistPath'

export const BlogGuard: FC = props => {
  const { children } = props
  const { asPath } = useRouter()

  if (blogPath.includes(asPath)) {
    return (
      <Layout>
        <FourOhFour localization={localization} />
      </Layout>
    )
  }
  return <div>{children}</div>
}

const localization: FourOhFourLocalization = {
  title: 'Ooops!',
  description: 'Looks like your are lost',
  button: 'Go to main page',
}

const blogPath: string[] = [
  ClinicPath.LOCATION,
  ClinicPath.FRONT,
  ClinicPath.GENERAL,
  ClinicPath.INSUARANCE,
  ClinicPath.POLICY,
  ClinicPath.ROOM,
  ClinicPath.SPECIALISTS,
  ClinicPath.TECHNOLOGIES,
  ClinicPath.TREATMENTS,

  PersonalPath.HOME,
  PersonalPath.HELP,
  PersonalPath.CLINICS,
  PersonalPath.SPECIALISTS,
  PersonalPath.SERVICES,
  PersonalPath.PROFILE,

  SpecialistPath.FRONT,
  SpecialistPath.CONTACTS,
  SpecialistPath.AWARDS,
  SpecialistPath.PUBLICATIONS,
  SpecialistPath.CREDENTIALS,
  SpecialistPath.LOCATIONS,
  SpecialistPath.SERVICES,
  SpecialistPath.SPECIALTY,
  SpecialistPath.GENERAL,

  Path.LOCAL_TRENDS,
  Path.LOCAL_TRENDS_CATEGORY,
  Path.MEDICAL_NEWS,
  Path.MEDICAL_NEWS_CATEGORY,
  Path.MV_NEWS,
  Path.MV_NEWS_CATEGORY,
  Path.LOGIN,
  Path.REGISTER,
]
