import { FC, ReactNode, useState } from 'react'
import styles from './image.module.scss'

interface Props {
  src: string
  preloader?: ReactNode
  width?: string | number
  height?: string | number
  alt?: string
}

const Image: FC<Props> = params => {
  const { src, width, height, alt, preloader = undefined } = params
  const [loaded, setLoaded] = useState(false)
  const showChiefImage = (): void => {
    setLoaded(true)
  }

  return (
    <>
      {preloader ? <div className={`${loaded ? styles.none : ''}`}>{preloader}</div> : ''}
      <img
        className={`${loaded ? '' : styles.none}`}
        src={src}
        onLoad={showChiefImage}
        loading="lazy"
        width={width}
        height={height}
        alt={alt}
      />
    </>
  )
}
export default Image
