export enum SpecialistPath {
  FRONT = '/specialist',
  GENERAL = '/specialist/general',
  SPECIALTY = '/specialist/specialty',
  SERVICES = '/specialist/services',
  LOCATIONS = '/specialist/locations',
  CREDENTIALS = '/specialist/credentials',
  PUBLICATIONS = '/specialist/publications',
  AWARDS = '/specialist/awards',
  CONTACTS = '/specialist/contacts',
}
