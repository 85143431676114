import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/dist/next-server/lib/router/router'
// import { useApollo } from '../graphql/apolloClient'
import { useApollo } from '../graphqlBlog/apolloClient'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import '../assets/css/bootstrap-grid.min.css'
import 'antd/dist/antd.css'
import 'tailwindcss/tailwind.css'
import '../assets/css/main.scss'
import { AuthContextProvider } from '../core/auth/AuthContext'
import { BlogGuard } from '../core/blog/BlogGuard'
import { init } from '@socialgouv/matomo-next'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as gtag from '../../lib/gtag'

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const router = useRouter()
  useEffect(() => {
    init({ url: 'https://metrika.bonum-studio.com', siteId: '9' })
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <BlogGuard>
          <Component {...pageProps} />
        </BlogGuard>
      </AuthContextProvider>
    </ApolloProvider>
  )
}

export default MyApp
