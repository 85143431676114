import { useMemo } from 'react'
import getConfig from 'next/config'
import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink } from '@apollo/client'

let apolloClient: ApolloClient<NormalizedCacheObject>

const { publicRuntimeConfig } = getConfig()

const httpLink = createHttpLink({
  uri: 'https://mapviser.com/wp/graphql',
})

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: httpLink,
    cache: new InMemoryCache(),
  })
}

export function getApolloClient(initialState: any = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export function useApollo(initialState: any) {
  return useMemo(() => getApolloClient(initialState), [initialState])
}
